//require('./bootstrap');

try {
    
    window.$ = window.jQuery = require('jquery');

    window.Popper = require('popper.js').default;
    require('datatables.net-bs4');
    require('datatables.net-responsive-bs4');
    require('datatables.net-buttons-bs4');
    require('datatables.net-buttons/js/buttons.colVis.js');
///    require('datatables.net-buttons/js/buttons.colVis.js');


} catch (e) {

	console.log ('scripts init in resourses/js/app.js', e);

}


//Helpers

String.prototype.format = function() {
    var formatted = this;
    if (arguments.length && typeof arguments[0] == 'object') {
        var vars = arguments[0];
        for (v in vars) {
            var regexp = new RegExp('\\{'+v+'\\}', 'gi');
            formatted = formatted.replace(regexp, vars[v]);
        }
    }
    return formatted;
};

format = function(str, replace) {
    var formatted = str;
        var vars = replace;
        for (v in vars) {
            var regexp = new RegExp('\\{'+v+'\\}', 'gi');
            formatted = formatted.replace(regexp, vars[v]);
    }
//    console.log ('formatted',formatted);
    return formatted;

};


require('overlayscrollbars');
require('../../vendor/almasaeed2010/adminlte/plugins/select2/js/select2.full.min.js');
require('../../vendor/almasaeed2010/adminlte/dist/js/adminlte');


